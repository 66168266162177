export default {
  user: null,
  role: null,
  token: null,
  unidade_selecionada: null,
  unidades_usuario: [],
  unidades_areas_campo_usuario: [],
  unidades_bloqueadas: false,
  aviso_pagamento_pendente: false,
  loading: false,
  Sidebar_drawer: true,
  Sidebar_drawer_changed_at: null,
  remember: null,
  auth_expired: false,
  token_updated_at: null,
  sila_atual: null,
  cookie_accept: false,
}
