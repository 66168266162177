import Vue from 'vue'
import constants from './constants'
import dayjs from 'dayjs'

Vue.filter('cnpj', value => value ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, (regex, arg1, arg2, arg3, arg4, arg5) => `${arg1}.${arg2}.${arg3}/${arg4}-${arg5}`) : value)

Vue.filter('telefone', value => value ? value.length === 11 ? value.replace(/(\d{2})(\d{5})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`) : value.replace(/(\d{2})(\d{4})(\d{4})/, (regex, arg1, arg2, arg3) => `(${arg1}) ${arg2}-${arg3}`) : '--')

Vue.filter('cpf', value => value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, (regex, arg1, arg2, arg3, arg4) => `${arg1}.${arg2}.${arg3}-${arg4}`) : value)

Vue.filter('currency', value => {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
})

Vue.filter('dataRelatorio', value => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date(value)
  const formattedDate = date.toLocaleDateString('pt-BR', options)

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1) // Capitalize the first letter
})

Vue.filter('formatarData', value => {
  const date = new Date(value)
  const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`

  return `${formattedDate}`
})

Vue.filter('formatarHora', value => {
  const date = new Date(value)
  const formattedTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`

  return `${formattedTime}`
})

Vue.filter('formatarDataHora', value => {
  const date = new Date(value)
  const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
  const formattedTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`

  return `${formattedDate} • ${formattedTime}`
})

Vue.filter('tamanhoDescricao', value => {
  if (value.length > 180) {
    return value.slice(0, 180) + '...'
  } else {
    return value
  }
})

Vue.filter('tamanhoTitulo', value => {
  if (value.length > 25) {
    return value.slice(0, 25) + '...'
  } else {
    return value
  }
})

Vue.filter('silaTipo', value => constants.sila_tipo_list?.find(o => o.value === value)?.label || '')

Vue.filter('date', value => dayjs(value).format('DD/MM/YYYY'))

Vue.filter('tipo_plano', value => constants.unidade_tipo_plano_list.find(e => e.value === value)?.label || '--')

Vue.filter('status_unidade', value => constants.unidade_status_list.find(e => e.value === value)?.label || '--')

Vue.filter('status_processamento_assincrono', value => constants.processamento_assincrono_status_list.find(e => e.value === value)?.label || '--')

Vue.filter('status_sila', value => constants.sila_status_list.find(e => e.value === value)?.label || '--')

Vue.filter('tipo_usuario', value => constants.usuario_tipo_list.find(e => e.value === value)?.label || '--')

Vue.filter('uppercase', value => value?.toString()?.toUpperCase())

Vue.filter('formatEmenta', value => value?.toString()?.replace(/\((?:Revogad[oa] pelo\(a\)?|Alterad[oa] pelo\(a\)?|Revoga(?: o\(a\))?) [^)]+\);|\(Alterado\(a\) pelo\(a\) [^)]+\);/g, '').replace(/\((?:Revogad[oa] pelo\(a\)?|Alterad[oa] pelo\(a\)?|Revoga(?: o\(a\))?) [^)]+\)|\(Alterado\(a\) pelo\(a\) [^)]+\)/g, ''))
// Vue.filter('formatEmenta', value => value?.toString()?.replace(/\((?:Revogad[oa] pelo\(a\)?|Alterad[oa] pelo\(a\)?|Revoga(?: o\(a\))?) [^)]+\)|\(Alterado\(a\) pelo\(a\) [^)]+\)/g, ''))


export default Vue
