import axios from 'axios'
import store from '../store'
let counter = 0

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000
})

function increment () {
  if (counter <= 0) store.dispatch('SET_LOADING', true)
  counter++
}

function decrement () {
  counter--
  if (counter <= 0) store.dispatch('SET_LOADING', false)
}

http.interceptors.request.use(config => {
  config.headers.Authorization = store.getters.token ? `Bearer ${store.getters.token}` : null
  increment()
  return config
}, error => {
  decrement()
  Promise.reject(error)
})

http.interceptors.response.use(response => {
  decrement()
  return response
}, error => {
  decrement()
  const err = error?.response?.data?.error || 'Ocorreu um erro no servidor. Tente novamente mais tarde!'
  const status = error?.response?.status
  if (status) {
    if (status === 401) {
      window.getApp.$emit('APP_UNAUTHORIZED', err)
      return Promise.reject(new Error())
    } else if (status === 402) {
      window.getApp.$emit('APP_PAYMENT_REQUIRED', err)
      return Promise.reject(new Error())
    } else if (status === 403) {
      window.getApp.$emit('APP_FORBIDDEN', err)
    } else if (status === 406) {
      window.getApp.$emit('APP_RELOAD', err)
      return Promise.reject(new Error())
    } else if (status === 409) {
      window.getApp.$emit('SILA_UPDATED', error?.response?.data?.sila)
    } else if (status === 500) {
      window.getApp.$emit('APP_BAD_REQUEST', err)
    }
  }
  return Promise.reject(err)
})

export default http
