<template lang="pug">
v-card.mb-7
  v-toolbar(flat)
    v-toolbar-title
      | {{ heading }}
    v-spacer
  v-divider
  .pa-4
    slot
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    heading: {
      type: String,
      default: ''
    }
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>
