import Vue from 'vue'
import { default as VueQuillEditor, Quill } from "vue-quill-editor"

import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue'

Quill.register("modules/imageDrop", ImageDrop)
Quill.register("modules/imageResize", ImageResize)

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

Vue.use(VueQuillEditor)

export default Vue
