import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMultiTabState from 'vuex-multi-tab-state'

import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const validateNewState = (new_state) => {
  if (
    new_state?.token &&
    state?.token &&
    new_state?.token !== state?.token &&
    new_state?.token_updated_at &&
    state?.token_updated_at &&
    new_state?.token_updated_at < state?.token_updated_at
  ) {
    return false
  }
  return new_state
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState(),
    createMultiTabState({
      statesPaths: [
        'user',
        'role',
        'token',
        'auth_expired',
        'token_updated_at'
      ],
      onBeforeSave: validateNewState,
      onBeforeReplace: validateNewState,
    }),
  ],
  strict: process.env.NODE_ENV !== 'production'
})

createPersistedState({
  key: 'backoffice',
  paths: [],
  storage: window.localStorage
})(store)

export default store
