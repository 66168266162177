const permissoes_list = [
  { value: 'dashboard', label: 'Dashboard' },
  { value: 'pais', label: 'País' },
  { value: 'estado', label: 'Estado' },
  { value: 'cidade', label: 'Cidade' },
  { value: 'admin', label: 'Admin' },
  { value: 'area-campo', label: 'Áreas Campo' },
  { value: 'tipo-legislacao', label: 'Tipo de Legislacao' },
  { value: 'competencia', label: 'Competência' },
  { value: 'orgao', label: 'Órgão' },
  { value: 'tema', label: 'Tema' },
  { value: 'subtema', label: 'Subtema' },
  { value: 'risco', label: 'Risco' },
  { value: 'impacto', label: 'Impacto' },
  { value: 'oportunidade', label: 'Oportunidade' },
  { value: 'setor-admin', label: 'Setor Admin' },
  { value: 'empresa', label: 'Empresa' },
  { value: 'unidade', label: 'Unidade' },
  { value: 'atividade', label: 'Atividade' },
  { value: 'escopo', label: 'Escopo' },
  { value: 'legislacao', label: 'Legislação' },
  { value: 'sila-gestao', label: 'Gestão SILA' },
  { value: 'sila-aprovacao', label: 'Aprovação SILA' },
  { value: 'sila-relatorio', label: 'Relatórios SILA' },
  { value: 'sila-verificacao', label: 'Verificação SILA' },
  { value: 'sila-log', label: 'Logs SILA' },
  { value: 'sila-backup', label: 'Backups'},
  { value: 'plano-de-acao', label: 'Planos de Ação' },
  { value: 'atualizacao-leis', label: 'Atualização de Leis em Massa' },
  { value: 'fale-conosco', label: 'Fale Conosco' },
  { value: 'aviso', label: 'Avisos' },
  { value: 'auditor', label: 'Auditores' },
  { value: 'categoria-fale-conosco', label: 'Categoria de Fale Conosco' },
  { value: 'relatorio-gerencial', label: 'Relatório de Unidades por Lei'},
  { value: 'destroy-data', label: 'Excluir Registros'},
]

const atividade_padrao_list = [
  { value: true, label: 'IA' },
  { value: false, label: 'CLIENTE' },
]

const unidade_status_list = [
  { value: 'ativo', label: 'Ativo' },
  { value: 'suspenso', label: 'Suspenso' },
  { value: 'cancelado', label: 'Cancelado' },
]

const unidade_tipo_plano_list = [
  { value: 'leg', label: 'Legislação' },
  { value: 'leg_check', label: 'Legislação + Checklist' },
  { value: 'leg_check_corp', label: 'Legislação + Checklist + GDL' },
]

const unidade_tipo_renovacao_list = [
  { value: 'adi', label: 'ADI' },
  { value: 'pro_aut', label: 'Pro AUT' },
  { value: 'pedido_compra', label: 'Pedido de Compra' },
]

const unidade_origem_list = [
  { value: 'indicacao_cliente', label: 'Indicação de um cliente' },
  { value: 'indicacao_ex_cliente', label: 'Indicação de um ex-cliente' },
  { value: 'indicacao_auditor', label: 'Indicação de um auditor' },
  { value: 'indicacao_consultor', label: 'Indicação de um consultor' },
  { value: 'internet', label: 'Internet' },
]

const legislacao_status_list = [
  { value: 'vigente', label: 'Vigente' },
  { value: 'revogada', label: 'Revogada' },
]

const legislacao_alterada_alvo_status_list = [
  { value: 'altera', label: 'Altera o(a)'  },
  { value: 'revoga', label: 'Revoga o(a)' },
  { value: 'remete', label: 'Remete a(ao)' },
]

const legislacao_alterada_origem_status_list = [
  { value: 'altera', label: 'Alterado(a) pelo(a)' },
  { value: 'revoga', label: 'Revogado(a) pelo(a)' },
  { value: 'remete', label: 'Remetido(a) pelo(a)' },
]

const sila_status_list = [
  { value: 'aguardando_aprovacao', label: 'Aguardando Aprovação' },
  { value: 'aprovada', label: 'Aprovada' },
  { value: 'aprovando', label: 'Aprovando' },
  { value: 'arquivada', label: 'Arquivada' },
]

const sila_verificacao_list = [
  { value: 'nao_verificada', label: 'Não Verificada' },
  { value: 'atendida', label: 'Atendida' },
  { value: 'parcialmente_atendida', label: 'Parcialmente Atendida' },
  { value: 'nao_atendida', label: 'Não Atendida' },
  { value: 'ciente', label: 'Ciente' },
]

const sila_tipo_list = [
  { value: 'real', label: 'Real' },
  { value: 'potencial', label: 'Potencial' },
  { value: 'outros', label: 'Normas Técnicas' },
  { value: 'corporativas', label: 'GDL' },
]

const sila_tipo_evidencia_list = [
  { value: 'arquivo', label: 'Arquivo Externo' },
  { value: 'corporativa', label: 'GDL' },
]

const usuario_tipo_list = [
  { value: 'gestor', label: 'Gestor' },
  { value: 'master', label: 'Master' },
  { value: 'usuario', label: 'Usuário' },
]

const headers_tab_sila = {
  'real' : ['area_campo','competencia','orgao','estado','cidade','requisito','ementa', 'tema', 'subtema',
    'checklist','atendimento','comentario','planos_acao',
    'prazo','responsavel','evidencias','observacao','verificacao','data_verificacao', 'preenchimento'],

  'potencial':['area_campo','competencia','orgao','estado','cidade','requisito','ementa', 'tema', 'subtema',
    'checklist','comentario','verificacao','data_verificacao', 'preenchimento'],

  'outros':['area_campo','competencia','orgao','estado','cidade','requisito', 'ementa','tema', 'subtema',
    'checklist','comentario','verificacao','data_verificacao', 'preenchimento'],

  'corporativas' : ['area_campo','competencia','orgao','estado','cidade','requisito', 'ementa', 'tema', 'subtema',
    'checklist','atendimento','comentario','planos_acao',
    'prazo','responsavel','evidencias','observacao','verificacao','data_verificacao'],
}

const verificacao_tab_sila = {
  'real' : ['nao_verificada', 'atendida', 'parcialmente_atendida', 'nao_atendida'],
  'potencial' : ['nao_verificada', 'atendida', 'ciente', 'parcialmente_atendida', 'nao_atendida'],
  'outros' : ['nao_verificada', 'atendida', 'parcialmente_atendida', 'nao_atendida'],
  'corporativas' : ['nao_verificada', 'atendida', 'parcialmente_atendida', 'nao_atendida'],
}

const campos_sila = [
  { value: 'atendimento', label: 'Atendimento' },
  { value: 'comentario', label: 'Comentário' },
  { value: 'prazo', label: 'Prazo' },
  { value: 'responsavel', label: 'Responsável' },
  { value: 'observacao', label: 'Observação' },
  { value: 'verificacao', label: 'Verificação' },
  { value: 'data_verificacao', label: 'Datas de Verificação' },
]

const planilhas_tipo_list = [
  { value: 'aspecto_impactos', label: 'Aspectos e Impactos' },
  { value: 'perigos_riscos', label: 'Perigos e Riscos' },
  { value: 'riscos_oportunidades', label: 'Riscos e Oportunidade' },
]

const contato_tipo_list = [
  { value: 'email', label: 'E-mail' },
  { value: 'telefone', label: 'Telefone' },
]

const processamento_assincrono_status_list = [
  { value: 'pendente', label: 'Pendente' },
  { value: 'processando', label: 'Processando' },
  { value: 'concluido', label: 'Concluído' },
  { value: 'erro', label: 'Erro' },
]

export default {
  permissoes_list,
  atividade_padrao_list,
  unidade_status_list,
  unidade_tipo_plano_list,
  unidade_tipo_renovacao_list,
  unidade_origem_list,
  legislacao_status_list,
  legislacao_alterada_alvo_status_list,
  legislacao_alterada_origem_status_list,
  sila_status_list,
  sila_verificacao_list,
  sila_tipo_evidencia_list,
  verificacao_tab_sila,
  sila_tipo_list,
  usuario_tipo_list,
  headers_tab_sila,
  planilhas_tipo_list,
  contato_tipo_list,
  campos_sila,
  processamento_assincrono_status_list,
}
