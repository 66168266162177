import Vue from 'vue'
import Router from 'vue-router'
// import goTo from 'vuetify/es5/services/goto'
import routes from './routes'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior: (to, from, savedPosition) => {
  //   let scrollTo = 0
  //   if (to.hash) {
  //     scrollTo = to.hash
  //   } else if (savedPosition) {
  //     scrollTo = savedPosition.y
  //   }
  //   return goTo(scrollTo)
  // },
  routes
})

const checkTipoUsuarioCliente = (unidade_selecionada, userTypes)=>{
  return (
    !unidade_selecionada || !userTypes ||
    (
      unidade_selecionada &&
      userTypes?.includes(unidade_selecionada.tipo)
    )
  )
}

const checkPermissions = (permissions, audience) =>
  !permissions ||
  (permissions?.toString()?.trim() === '') ||
  (permissions?.toString()?.trim()?.includes(audience))

const checkUnidadePermissions = (unidade, unidadePermissions) => {
  if (!unidade || !unidadePermissions) return true

  for (const i in unidadePermissions) {
    const permission = unidadePermissions[i]
    if (
      (permission !== 'corporativa' && unidade[permission]) ||
      (permission === 'corporativa' && unidade.tipo_plano === 'leg_check_corp')
    )
      return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  if (to?.meta?.public) {
    next()
  } else if (to.meta.allow && to.meta.audience) {
    const role = store?.getters?.user?.role || 'user'

    if ((store?.getters?.auth_expired || !store?.getters?.user) && from.path === '/') {
      store.dispatch('RESET_STORE')
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }

    if (store.getters.user &&
      checkPermissions(store.getters.permissoes, to.meta.audience) &&
      to.meta.allow.includes(role) &&
      checkTipoUsuarioCliente(store.getters.unidade_selecionada, to.meta?.userTypes) &&
      checkUnidadePermissions(store.getters.unidade_selecionada?.unidade,to.meta?.unidadePermissions)
    ) {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

export default router
