import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/pt_BR.json'
import { validateCNPJ } from 'validations-br'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  })
})

extend('min_time', (value, min) => {
  if (value >= min[0]) {
    return true
  }
  return `O menor horário permitido é ${min[0]}`
})

extend('max_time', (value, max) => {
  if (value <= max[0]) {
    return true
  }
  return `O maior horário permitido é ${max[0]}`
})

extend('cnpj', value => {
  if (validateCNPJ(value)) {
    return true
  }
  return `O campo {_field_} deve ser um CNPJ válido`
})


extend('minDateGeneral', value => {
  const dayjs = Vue.prototype.$dayjs
  if (value && value.toString().trim() !== '') {
    if (dayjs(value).isValid() && dayjs(value).isBefore(dayjs('1900-01-01', 'YYYY-MM-DD'))) {
      return `O campo {_field_} deve ter uma data válida`
    }
  }
  return true
})

extend('maxDateGeneral', value => {
  const dayjs = Vue.prototype.$dayjs
  if (value && value.toString().trim() !== '') {
    if (dayjs(value).isValid() && dayjs(value).isAfter(dayjs().add(20, 'years'))) {
      return `O campo {_field_} deve ter uma data válida`
    }
  }
  return true
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default Vue
