import sessaoService from '@/services/sessao'

export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function () {
      this.$store.dispatch('SET_AUTH_EXPIRED', false)
      if (this.$route.path.indexOf('/login') > -1 && this.$route?.query?.redirect) {
        this.$router.push({ path: this.$route.query.redirect })
      } else if (this.$route.name !== 'Dashboard') {
        this.$router.push({ path: '/dashboard' })
      }
      this.$store.dispatch('SET_COOKIE_ACCEPT', true)
    }
  },
  {
    name: 'APP_ALERT',
    callback: function ({ color = 'error', text }) {
      if (typeof text === 'string') {
        this.snackbar = {
          show: true,
          color,
          text,
          timeout: color === 'error' ? 5000 : 3000,
        }
      }
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: async function () {
      try {
        await sessaoService.logout()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('RESET_STORE')
        this.$route.path.indexOf('/login') === -1 && this.$router.push({ path: '/login' })
      }
    }
  },
  {
    name: 'APP_FORBIDDEN',
    callback: function (err) {
      window.getApp.$emit('APP_ALERT', { color: 'error', text: err || 'Usuário e/ou senha inválidos!' })
      this.$store.dispatch('RESET_STORE')
      this.$route.path.indexOf('/login') === -1 && this.$router.push({ path: '/login', query: { redirect: this.$route.path } })
    }
  },
  {
    name: 'APP_UNAUTHORIZED',
    callback: function () {
      // window.getApp.$emit('APP_ALERT', { color: 'error', text: 'Credenciais inválidas!' })
      this.$store.dispatch('SET_AUTH_EXPIRED', true)
    }
  },
  {
    name: 'APP_RELOAD',
    callback: function () {
      this.$router.go(this.$router.currentRoute)
    }
  },
  {
    name: 'SILA_UPDATED',
    callback: function (sila) {
      this.recentSila = sila
      this.dialogNewSila = true
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    callback: function (err) {
      window.getApp.$emit('APP_ALERT', { color: 'error', text: err || 'Ocorreu um erro no servidor. Tente novamente mais tarde!' })
    }
  },
  {
    name: 'APP_DIALOG_AUX',
    callback: function () {
      this.dialogAux = true
      this.dialogAux = false
    }
  },
  {
    name: 'APP_PAYMENT_REQUIRED',
    callback: function () {
      this.dialogPaymentRequired = true
    }
  },
  {
    name: 'APP_UNIDADES_BLOQUEADAS',
    callback: function () {
      this.dialogUnidadesBloqueadas = true
    }
  },
  {
    name: 'APP_PAYMENT_PENDING',
    callback: function (unidadeSelecionar) {
      this.unidadeSelecionar = unidadeSelecionar
      this.daysPaymentPending = this.$dayjs(unidadeSelecionar.unidade.data_suspensao_automatica).startOf('day').diff(this.$dayjs().startOf('day'), 'day')
      this.dialogPaymentPending = true
    }
  },
]
