import http from '../http'

const getAll = async (params = {}) => (await http.get('/sila', { params })).data

const getGroupByUnidade = async (params = {}) => (await http.get('/sila/unidade/group', { params })).data

const countAll = async (params = {}) => (await http.get('/sila/count/all', { params })).data

const getById = async (id) => (await http.get(`/sila/${id}`)).data

const create = async (payload) => (await http.post('/sila', payload)).data

const update = async (id, payload) => (await http.put(`/sila/${id}`, payload)).data

const approve = async (payload) => (await http.post('/sila/aprovar', payload)).data

const approveMany = async (payload) => (await http.post('/sila/aprovar/many', payload)).data

const generateNewVersion = async (payload) => (await http.post('/sila/versao', payload)).data

const download = async (id, params = {}) => (await http.get(`/sila/${id}/download`, { params })).data

const updateDataVerificacao = async (id, payload) => (await http.put(`/sila/verificacao/data/${id}`, payload)).data

const updateAviso = async (id, payload) => (await http.put(`/sila/verificacao/aviso/${id}`, payload)).data

const checkImportLeis = async (params = {}) => (await http.get('/sila/check/importar', { params })).data
const importLeis = async (payload) => (await http.post('/sila/leis/importar', payload)).data

const getForUpdate = async (legislacao_id) => (await http.get(`/sila/update/${legislacao_id}`)).data

const getPreview = async (id) => (await http.get(`/sila/${id}/pre-visualizar`)).data

export default {
  getAll,
  countAll,
  getGroupByUnidade,
  getById,
  getForUpdate,
  create,
  update,
  approve,
  approveMany,
  generateNewVersion,
  download,
  updateDataVerificacao,
  updateAviso,
  importLeis,
  checkImportLeis,
  getPreview,
}
