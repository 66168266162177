<template lang="pug">
v-row.page-breadcrumb
  v-col.pa-0(cols='12' sm='12')
    v-card.px-2.custom-shaodow
      v-toolbar.transparent.pa-0(dense flat height='45px', :class="{'mb-5': !title}")
        v-toolbar-title.pt-3
          v-breadcrumbs.pa-0(:items='breadcrumbs')
            template(v-slot:item="{ item }")
              v-breadcrumbs-item(:to="item.to" exact :disabled="item.disabled") {{ item.text }}
            template(v-slot:divider)
              v-icon mdi-chevron-right
          slot
        v-toolbar-title.toobar-extension.transparent.pb-3(slot='extension', v-if="title")
          span.text--primary {{ title }}
            .text--secondary.text-body-2.font-weight-regular {{ subtitle }}
        v-spacer

</template>

<script>
export default {
  name: 'BaseBreadcrumb',
  props: {
    title: String,
    subtitle: String,
    breadcrumbs: Array
  },
  data: () => ({})
}
</script>

<style lang="scss">
.page-breadcrumb {
  margin: -24px -20px 27px -25px;
}
</style>
