import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import Vuetify from './Vuetify'

Vue.use(VuetifyConfirm, {
  vuetify: Vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Não',
  buttonTrueColor: 'secondary',
  buttonTrueFlat: false,
  color: 'primary',
  icon: 'mdi-alert',
  title: 'Atenção',
  width: 500,
  property: '$confirm'
})

export default Vue
