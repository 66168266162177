<template lang="pug">
v-app#materialpro(:class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`")
  v-dialog(v-model="dialogAux")
  v-overlay(v-model="authExpired")
    v-dialog(v-model="authExpired", persistent, width="auto")
      v-card
        v-card-title Sessão Expirada
        v-card-text Você foi deslogado da sua conta por inatividade, faça login novamente para continuar navegando.
        v-card-actions
          v-spacer
          v-btn(color="secondary", @click="login") Fazer Login
  v-overlay(v-model="dialogPaymentRequired")
    v-dialog(v-model="dialogPaymentRequired", persistent, max-width="800")
      v-card
        v-card-title
          span.title Acesso Negado
          v-spacer
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon, @click="closeDialogPaymentRequired", v-on="on", v-bind="attrs")
                v-icon mdi-close
            span Fechar
        v-card-text
          div Caro cliente,
          div.mt-3 Seu acesso encontra-se suspenso devido a uma pendência financeira. Por gentileza informar seu setor financeiro e contatar nossa equipe a fim de restabelecermos o acesso.
          div.mt-3
            b Financeiro:
          div wancleia@inteligenciaambiental.com.br <br> (13) 99698-3335
  v-overlay(v-model="dialogPaymentPending")
    v-dialog(v-model="dialogPaymentPending", persistent, max-width="800")
      v-card
        v-card-title
          span.title Pendência Financeira
          v-spacer
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon, @click="closeDialogPaymentPending", v-on="on", v-bind="attrs")
                v-icon mdi-close
            span Fechar
        v-card-text
          div Caro cliente,
          div.mt-3 Existe uma pendência financeira! Faltam {{ daysPaymentPending }} dias para seu acesso ser bloqueado devido a uma pendência financeira. Por gentileza informar seu setor financeiro e contatar nossa equipe.
          div.mt-3
            b Financeiro:
          div wancleia@inteligenciaambiental.com.br <br> (13) 99698-3335
  v-overlay(v-model="dialogUnidadesBloqueadas")
    v-dialog(v-model="dialogUnidadesBloqueadas", persistent, max-width="800")
      v-card
        v-card-title
          span.title Unidade Bloqueada
          v-spacer
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon, @click="dialogUnidadesBloqueadas = false;", v-on="on", v-bind="attrs")
                v-icon mdi-close
            span Fechar
        v-card-text
          div Caro cliente,
          div.mt-3 Seu acesso a uma de suas unidades encontra-se suspenso devido a uma pendência financeira. Por gentileza informar seu setor financeiro e contatar nossa equipe a fim de restabelecermos o acesso.
          div.mt-3
            b Financeiro:
          div wancleia@inteligenciaambiental.com.br <br> (13) 99698-3335
  v-dialog(v-model="dialogNewSila", persistent, width="auto")
    v-card
      v-card-title SILA Atualizada
      v-card-text Uma nova versão dessa SILA foi criada, atualize para continuar a verificação.
      v-card-actions
        v-spacer
        v-btn(color="secondary", @click="updateSila").text-none Atualizar
  v-system-bar(:height="30", color="red", app, v-if="$root.isDevelopment")
    v-spacer
    .text-center.white--text.font-weight-medium Ambiente de Testes
    v-spacer
  FullLayout(v-if="!$route.meta.public && $root.user", :class="{'pt-10': $root.isDevelopment}")
    router-view
  BlankLayout(v-else)
    router-view
  v-snackbar(:timeout='snackbar?.timeout || 3000' top right :color='snackbar.color' v-model='snackbar.show')
    | {{ snackbar.text }}
    template(v-slot:action="{ attrs }")
      v-btn(v-bind="attrs" dark text @click.native='snackbar.show = false' icon)
        v-icon mdi-close
  //- CookieControl(v-if="!$root.isAdmin")
</template>

<script>
import { mapState } from 'vuex'
import AppEvents from './events'
import adminService from '@/services/admin'
import auditorService from '@/services/auditor'
import usuarioService from '@/services/usuario'
import silaService from '@/services/sila'

export default {
  name: 'App',
  data: () => ({
    dialogAux: false,
    dialogNewSila: false,
    dialogPaymentRequired: false,
    dialogPaymentPending: false,
    dialogUnidadesBloqueadas: false,
    daysPaymentPending: 0,
    interval: null,
    recentSila: {},
    authExpired: false,
    unidadeSelecionar: null,
    snackbar: {
      show: false,
      text: '',
      color: '',
    }
  }),
  components: {
    BlankLayout: () => import('@/layouts/blank-layout/BlankLayout.vue'),
    FullLayout: () => import('@/layouts/full-layout/FullLayout.vue'),
    CookieControl: () => import('@/components/commonComponents/CookieControl.vue'),
  },
  computed: {
    ...mapState(['unidade_selecionada', 'unidades_usuario', 'auth_expired', 'token', 'unidades_bloqueadas', 'aviso_pagamento_pendente']),
    isLoginRoute () {
      return this.$route.path === '/' || this.$route.path.indexOf('/login') > -1
    },
  },
  async created () {
    const vm = this
    AppEvents.forEach(item => {
      vm.$on(item.name, item.callback)
    })
    window.getApp = vm
    document.title = `Inteligência Ambiental - ${vm.$root.userEnvironment === 'admin' ? 'Admin' : vm.$root.userEnvironment === 'auditor' ? 'Auditor' : 'Cliente'}`
    vm.auth_expired && vm.login()
    vm?.$root?.user?.id && await vm.loginValidate()
  },
  watch: {
    $route (route) {
      const vm = this
      !!vm.interval && clearInterval(vm.interval)
      if (route.meta.public && vm.$root.user && !vm.auth_expired) {
        window.getApp.$emit('APP_LOGIN_SUCCESS')
      }
      else if (!vm.$root.user && !vm.isLoginRoute) {
        window.getApp.$emit('APP_UNAUTHORIZED')
      }
      if (!vm.isLoginRotue && route.path.indexOf('/sila/') > -1 && route.path.indexOf('/verificacao') > -1 && route.params.id) {
        vm.getSila(route.params.id)
      }
      vm.checkHideAdoptButton()
    },
    unidade_selecionada (previous, current) {
      const vm = this
      if (!previous?.id || (previous?.id !== current?.id)) {
        vm.$router.go(vm.$router.currentRoute)
      }
    },
    token (val) {
      const vm = this
      setTimeout(() => {
        !val && !vm.isLoginRoute && vm.$store.dispatch('SET_AUTH_EXPIRED', false) && vm.$router.push({ path: '/login', query: { redirect: vm.$route.path } })
        !!val && vm.isLoginRoute && window.getApp.$emit('APP_LOGIN_SUCCESS')
      }, 100)
    },
    auth_expired (val) {
      this.authExpired = this.isLoginRoute ? false : val
    },
  },
  async mounted () {
    const vm = this
    //modificado para o carregamento do header;
    // try{
    //   if (this.$root.isUser && this.unidades_usuario) {
    //     const unidades_id = this.unidades_usuario.map(o => o.unidade_id)
    //     const { rows: silas } = await silaService.getAll({
    //       unidades_id,
    //       status: 'aprovada'
    //     })
    //     console.log('silas', silas)
    //     this.$store.dispatch('SET_SILA_ATUAL', silas)
    //   }
    // }catch(e){
    //   console.error(e)
    // }
    this.checkHideAdoptButton()
    setTimeout(() => {
      !!vm.unidades_bloqueadas && window.getApp.$emit('APP_UNIDADES_BLOQUEADAS') && vm.$root.setUnidadesBloqueadas(false)
      !!vm.aviso_pagamento_pendente && window.getApp.$emit('APP_PAYMENT_PENDING', vm.unidade_selecionada) && vm.$root.setAvisoPagamentoPendente(false)
    }, 500)
  },
  methods: {
    closeDialogPaymentRequired () {
      window.getApp.$emit('APP_LOGOUT')
      this.dialogPaymentRequired = false
    },
    closeDialogPaymentPending () {
      !!this.unidadeSelecionar && this.$root.setUnidadeSelecionada(this.unidadeSelecionar)
      this.unidadeSelecionar = null
      this.dialogPaymentPending = false
    },
    checkHideAdoptButton () {
      if (this.$route.path.indexOf('/login') > -1) {
        document?.getElementById('adopt-controller-button')?.classList?.remove('hideAdoptButton')
      } else {
        document?.getElementById('adopt-controller-button')?.classList?.add('hideAdoptButton')
      }
    },
    getServiceByRole () {
      return {
        'admin': adminService,
        'auditor': auditorService,
        'user': usuarioService,
      }[this.$root.role]
    },
    async loginValidate () {
      const vm = this
      try {
        const service = vm.getServiceByRole()
        const { user, token, unidades, unidades_areas_campo } = await service.loginValidate()

        vm.$root.setUser(user)
        vm.$root.setToken(token)
        vm.$root.setRole(user.role)

        if (!!unidades && unidades.length) {
          const unidadeUsuario = unidades.find(({ unidade }) => unidade.status === 'ativo')

          vm.$root.setUnidadesUsuario(unidades)
          !vm.unidade_selecionada && vm.$root.setUnidadeSelecionada(unidadeUsuario)
          !!vm.unidade_selecionada && !unidades.find(({ id, unidade }) => id === vm.unidade_selecionada.id && unidade.status === 'ativo') && vm.$root.setUnidadeSelecionada(unidadeUsuario)
          !!vm.unidade_selecionada && unidades.find(({ id, unidade }) => id === vm.unidade_selecionada.id && unidade.status === 'ativo') && vm.$root.setUnidadeSelecionada(unidades.find(unidade => unidade.id === vm.unidade_selecionada.id))
        }

        if (!!unidades_areas_campo && unidades_areas_campo?.length) {
          vm.$root.setUnidadesAreasCampoUsuario(unidades_areas_campo)
        }
      } catch (error) {
        console.error({ error })
      }
    },
    async checkNewSila (sila) {
      // console.log('checkNewSila');
      const vm = this
      if (!sila?.id) {
        return
      }
      try {
        const { rows: silas } = await silaService.getAll({
          unidade_id: sila.unidade_id,
          status: 'aprovada',
          pageSize: 1,
          page: 1,
        })
        const [recentSila] = silas
        if (recentSila?.id != sila.id) {
          !!vm.interval && clearInterval(vm.interval)
          vm.recentSila = recentSila
          vm.dialogNewSila = true
        }
      } catch (error) {
        // window.getApp.$emit('APP_ALERT', { color: 'error', text: error || 'Ocorreu um erro ao buscar os dados. Atualize a página!' })
      }
    },
    async getSila (sila_id) {
      const vm = this
      if (!sila_id) {
        return
      }
      try {
        const sila = await silaService.getById(sila_id)
        if (sila?.status === 'aprovada') {
          vm.checkNewSila(sila)
          vm.interval = setInterval(() => {
            vm.checkNewSila(sila)
          }, 15000)
        }
      } catch (error) {
        // window.getApp.$emit('APP_ALERT', { color: 'error', text: error || 'Ocorreu um erro ao buscar os dados. Atualize a página!' })
      }
    },
    updateSila () {
      const vm = this
      const url = vm.$router.resolve({ name: 'SilaVerificacaoAcesso', params: { id: vm.recentSila.id }}).href
      vm.dialogNewSila = false
      window.open(url, '_blank')
      window.close()
    },
    login () {
      this.authExpired = false
      this.$store.dispatch('RESET_STORE')
      !this.isLoginRoute && this.$router.push({ path: '/login', query: { redirect: this.$route.path } })
    }
  }
}
</script>

<style lang="scss">
@import './scss/variables';
form {
  width: 100%!important;
}
[v-cloak] {
  display: none;
}
.v-system-bar {
  z-index: 999!important;
}
.hideAdoptButton {
  visibility: hidden!important;
  display: none!important;
  width: 0!important;
  height: 0!important;
}
.v-snack--top {
  top: 70px!important;
  @media (max-width: 768px) {
    top: 60px!important;
  }
}
// #nprogress .spinner-icon {
//   border-top-color: white!important;
//   border-left-color: white!important;
// }
// #nprogress .bar {
//   background: white!important;
// }
</style>
