const SET_ROLE = (state, payload) => {
  state.role = payload
}

const SET_USER = (state, payload) => {
  state.user = payload
}

const SET_TOKEN = (state, payload) => {
  state.token = payload
  state.token_updated_at = payload ? new Date().getTime() : null
}

const SET_UNIDADE_SELECIONADA = (state, payload) => {
  state.unidade_selecionada = payload
}

const SET_UNIDADES_USUARIO = (state, payload) => {
  state.unidades_usuario = payload
}

const SET_UNIDADES_AREAS_CAMPO_USUARIO = (state, payload) => {
  state.unidades_areas_campo_usuario = payload
}

const SET_UNIDADES_BLOQUEADAS = (state, payload) => {
  state.unidades_bloqueadas = payload
}

const SET_AVISO_PAGAMENTO_PENDENTE = (state, payload) => {
  state.aviso_pagamento_pendente = payload
}

const SET_LOADING = (state, payload) => {
  state.loading = payload
}

const SET_SIDEBAR_DRAWER = (state, payload) => {
  const timestamp = new Date().getTime()
  if (state.Sidebar_drawer === payload) return
  else if (state.Sidebar_drawer_changed_at && timestamp - state.Sidebar_drawer_changed_at < 500) return false
  state.Sidebar_drawer = payload
  state.Sidebar_drawer_changed_at = timestamp
}

const SET_REMEMBER = (state, payload) => {
  state.remember = payload
}

const SET_AUTH_EXPIRED = (state, payload) => {
  state.auth_expired = payload
}

const SET_SILA_ATUAL = (state, payload) => {
  state.sila_atual = payload
}

const SET_COOKIE_ACCEPT = (state, payload) => {
  state.cookie_accept = payload
}

export {
  SET_ROLE,
  SET_USER,
  SET_TOKEN,
  SET_UNIDADE_SELECIONADA,
  SET_UNIDADES_USUARIO,
  SET_UNIDADES_AREAS_CAMPO_USUARIO,
  SET_UNIDADES_BLOQUEADAS,
  SET_AVISO_PAGAMENTO_PENDENTE,
  SET_LOADING,
  SET_SIDEBAR_DRAWER,
  SET_REMEMBER,
  SET_AUTH_EXPIRED,
  SET_SILA_ATUAL,
  SET_COOKIE_ACCEPT,
}
