import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'
import '@/scss/vuetify/overrides.scss'

Vue.use(Vuetify)

const theme = {
  primary: '#015198',
  'primary--light': '#74B5F1',
  secondary: '#85C226',
  'secondary--light': '#9dd93d',
  error: '#F67361',
  warning: '#FECB68',
  'warning--light': '#FFD54F',
  'warning--dark': '#dea027',
  info: '#015198',
  success: '#85C226',
  purple: '#875AB2'
}

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    },
    dark: false // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
})
