const user = ({ user }) => user

const role = ({ role }) => role

const token = ({ token }) => token

const unidade_selecionada = ({ unidade_selecionada }) => unidade_selecionada

const unidades_usuario = ({ unidades_usuario }) => unidades_usuario

const unidades_areas_campo_usuario = ({ unidades_areas_campo_usuario }) => unidades_areas_campo_usuario

const unidades_bloqueadas = ({ unidades_bloqueadas }) => unidades_bloqueadas

const aviso_pagamento_pendente = ({ aviso_pagamento_pendente }) => aviso_pagamento_pendente

const loading = ({ loading }) => loading

const Sidebar_drawer = ({ Sidebar_drawer }) => Sidebar_drawer

const remember = ({ remember }) => remember

const auth_expired = ({ auth_expired }) => auth_expired

const token_updated_at = ({ token_updated_at }) => token_updated_at

const sila_atual = ({ sila_atual }) => sila_atual

const cookie_accept = ({ cookie_accept }) => cookie_accept

export {
  user,
  role,
  token,
  unidade_selecionada,
  unidades_usuario,
  unidades_areas_campo_usuario,
  unidades_bloqueadas,
  aviso_pagamento_pendente,
  loading,
  Sidebar_drawer,
  remember,
  auth_expired,
  token_updated_at,
  sila_atual,
  cookie_accept,
}
