import http from '../http'

const loginValidate = async (payload) => (await http.post('/usuario/login/validate', payload)).data

const login = async (payload) => (await http.post('/usuario/login', payload)).data

const recoveryPassword = async (payload) => (await http.post('/usuario/password/recovery', payload)).data

const getAll = async (params = {}) => (await http.get('/usuario', { params })).data

const getById = async (id) => (await http.get(`/usuario/${id}`)).data

const create = async (payload) => (await http.post('/usuario', payload)).data

const update = async (id, payload) => (await http.put(`/usuario/${id}`, payload)).data

const updateMe = async (payload) => (await http.put('/usuario', payload)).data

const updateMyPassword = async (payload) => (await http.put('/usuario/password', payload)).data

const destroy = async (id) => (await http.delete(`/usuario/${id}`)).data

export default {
  login,
  loginValidate,
  recoveryPassword,
  getAll,
  getById,
  create,
  update,
  updateMe,
  updateMyPassword,
  destroy
}
