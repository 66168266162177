import http from '../http'

const loginValidate = async (payload) => (await http.post('/auditor/login/validate', payload)).data

const login = async (payload) => (await http.post('/auditor/login', payload)).data

const recoveryPassword = async (payload) => (await http.post('/auditor/password/recovery', payload)).data

const getAll = async (params = {}) => (await http.get('/auditor', { params })).data

const create = async (payload) => (await http.post('/auditor', payload)).data

const update = async (id, payload) => (await http.put(`/auditor/${id}`, payload)).data

const updateMe = async (payload) => (await http.put('/auditor', payload)).data

const updateMyPassword = async (payload) => (await http.put('/auditor/password', payload)).data

const destroy = async (id) => (await http.delete(`/auditor/${id}`)).data

export default {
  login,
  loginValidate,
  recoveryPassword,
  getAll,
  create,
  update,
  updateMe,
  updateMyPassword,
  destroy
}
